<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Work Modern </h4>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Work</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Works</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Work Start -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <ul class="col container-filter list-unstyled categories-filter text-center" id="filter">
                <li class="list-inline-item categories-name border text-dark rounded ms-2" (click)="activeCategory('all')"
                    [ngClass]="{'active': galleryFilter ==='all'}">All</li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('branding')" [ngClass]="{'active': galleryFilter ==='branding'}">Branding
                </li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('designing')" [ngClass]="{'active': galleryFilter ==='designing'}">Designing
                </li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('photography')" [ngClass]="{'active': galleryFilter ==='photography'}">
                    Photography</li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('development')" [ngClass]="{'active': galleryFilter ==='development'}">
                    Development</li>
            </ul>
        </div>
        <!--end row-->

        <div id="grid" class="row row-cols-lg-5 mt-4 pt-2">
            @for(item of filterredImages;track $index){
            <div class="col-lg col-md-4 col-12 spacing picture-item" data-groups='["branding"]'>
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="{{item.image}}" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a routerLink="/portfolio-detail-one"
                                class="title text-white d-block fw-bold">{{item.title}}</a>
                            <small class="text-light">{{item.type}}</small>
                        </div>
                        <div class="client">
                            <small class="text-light user d-block"><i class="uil uil-user"></i> {{item.name}}</small>
                            <small class="text-light date"><i class="muil uil-calendar-alt"></i> {{item.date}}</small>
                        </div>
                    </div>
                </div>
            </div>
        }
            <!--end col-->
        </div>
        <!--end row-->

        <!-- PAGINATION START -->
        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <!-- PAGINATION END -->

    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Work End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->